<template>
  <div>
    <meet-title
      :meetName="orderInfo.meetTitle"
      :meetDate="meetDate"
      :rebackFeeStatus="orderInfo.refundStatus"
    />
    <div class="infoBox">
      <ul>
        <template v-for="item in list">
          <li :key="item.key">
            <span class="infoName">{{ item.name }}</span>
            <span class="infoText">{{ item.value }}</span>
            <!-- <span
              class="viewInvoice"
              v-if="item.key == 'invoiceStatus' && invoiceStatus==2"
              >查看发票</span
            > -->
             <span
              class="viewInvoice"
              v-if="item.key == 'meetStatus'"
              @click="goDetailPage"
              >查看培训详情</span
            >
            <span
              class="viewInvoice"
              v-if="item.key == 'enrollment'"
              @click="goMemberListPage"
              >查看报名人员</span
            >
          </li>
        </template>
      </ul>
      <div v-if="orderInfo.signAmount>0">
        <div :class="(invoiceStatus==0||(orderInfo.refundStatus==1)||invoiceStatus==4)?'twoBtns':'oneButton'" >
          <Button v-if="invoiceStatus==0||(orderInfo.refundStatus==1)||invoiceStatus==4" class="mar-t-25" color="#2b72ff" @click="makeOutInvoice">
            开具发票
          </Button
          >
          <Button  class="mar-t-25" color="#2b72ff" @click="applyRefund">
            申请退费</Button
          >
          <!-- v-if='orderInfo.refundStatus!=2' -->
        </div>
      </div>
    </div>
    <!-- <Popup
      v-model="showReason"
      round
      closeable
      position="bottom"
      :style="{ height: '30%' }"
    >
      <p class="title">退费驳回原因</p>
      <p class="reason">
        {{ rejectReason }}
      </p>
    </Popup> -->
  </div>
</template>

<script>
import MeetTitle from "../meet/component/meetTitle.vue";
import { Button, Popup } from "vant";
import {getMeetOrderInfo,checkInvoice} from "@/api/my.js"

export default {
  name: "myMeetDetail",
  components: {
    MeetTitle,
    Button,
    Popup,
  },
  data() {
    return {
      orderId:'',
      orderInfo:{},
      invoiceStatus: false,
      rebackFeeStatus: 0, //0未退费 1审核中，2已驳回 3已退费
      // rejectReason:"",
      // showReason: false,
      list: [
        {
          name: "报名费用：",
          key: "signAmount",
          value: "100",
        },
        { name: "报名人数：", key: "enrollment", value: "1人" },
        { name: "培训状态：", key: "meetStatus", value: "未开始" },
        { name: "订单编号：", key: "id", value: "123456" },
        { name: "报名时间：", key: "createTime", value: "2021-06-11" },
        {
          name: "报名缴费：",
          key: "orderStatus",
          value: "",
        },
        {
          name: "开具发票：",
          key: "invoiceStatus",
          value: "",
        },
      ],
    };
  },
  computed:{
     meetDate(){
       if(this.orderInfo.meetTimeStart){
       let str=this.orderInfo.meetTimeStart.split('-').join('/')+"-"+this.orderInfo.meetTimeEnd.split('-').join('/')
       return str;
       }else{
         return ''
       }
     }
  },
  created() {
      this.orderId=this.$route.query.orderId
      this.getOrder(this.orderId);
  },
  methods: {
    //获取订单信息
    getOrder(orderId){
      getMeetOrderInfo({orderId}).then(res=>{
        if(res.status=="S"){
          this.orderInfo=res.data;
          this.invoiceStatus=res.data.invoiceStatus
          this.list.map(item=>{
            if(item.key=='signAmount'){
              item.value=res.data.signAmount+' 元'
            }
            else if(item.key=="enrollment"){
              item.value=res.data.enrollment+' 人'
            }else if(item.key=='meetStatus'){
              item.value=["未开始","报名中","报名结束","培训进行中","培训结束"][res.data.meetStatus];
            }else if(item.key=="orderStatus"){
              item.value=["未报名","报名成功","已取消","退款中","已退款"][res.data.orderStatus];
            }else if(item.key=="invoiceStatus"){
              item.value=["未开具","开票中","已开具","已红冲","开具失败"][res.data.invoiceStatus];
              //0：未开具 1：开具中 2：已开具
            }else{
              item.value=res.data[item.key]
            }
          })
        }
      })
    },
    // 申请退费
    applyRefund() {
      this.$router.push(`/applyRefund?orderId=${this.orderId}&type=meet&orderStatus=${this.orderInfo.refundStatus}`);
    },
    //开发票
    makeOutInvoice() {
      checkInvoice({billId:this.orderId}).then(res=>{
        if(res.status=="S"){
          this.$router.push(`/invoice?goodsId=0000&count=${this.orderInfo.enrollment}&invoiceMoney=${this.orderInfo.invoiceAmount}&money=${this.orderInfo.signAmount}&orderId=${this.orderId}&noCardId='noCardId'`);
        }
      })
      //以后goodId后面接的需要是参数，现在先写死0000是培训，1111是会员

    },
    goDetailPage(){
      this.$router.push('/meetDetail?id='+this.orderInfo.meetId)
    },
    goMemberListPage(){
      this.$router.push(`/myMemberList?orderId=${this.orderId}&meetId=${this.orderInfo.meetId}`)
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-popup {
  .title {
    font-size: 16px;
    font-weight: bold;
    padding: 16px;
    border-bottom: 1px solid #eee;
  }
  .reason {
    padding: 16px;
    line-height: 25px;
    font-size: 14px;
    color: #666;
  }
}
.infoBox {
  margin-top: -0.2rem;
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 0.1rem 0.12rem 0;
  ul {
    border-bottom: 1px solid #eee;
    padding: 0 0.13rem;
    padding-bottom: 0.3rem;
  }
  li {
    margin-top: 0.3rem;
    font-size: 14px;
    color: #999;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    span {
      line-height: 20px;
      display: inline-block;
    }
    .infoName {
      width: 70px;
    }
    .infoText {
      color: #333;
    }
    .viewInvoice {
      color: #2b72ff;
     position: absolute;
     right: 0;
    }
  }
  .mar-t-25 {
    border-radius: 5px;
    height: 0.42rem;
    margin-top: 0.25rem;
  }
  .twoBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mar-t-25 {
      width: 47%;
    }
  }
  .oneButton{
    .mar-t-25 {
      width:100%;
    }
  }
}
</style>
